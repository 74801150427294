import { ProcessorStatusType, Status } from "../models/ProcessesStatus";

const processStatusMapper = {
  toProcessStatus(type: ProcessorStatusType | undefined): Status {
    switch (type) {
      case ProcessorStatusType.STARTED:
        return Status.Started;
      case ProcessorStatusType.COMPLETED:
      case ProcessorStatusType.COMPLETE:
        return Status.Completed;
      case ProcessorStatusType.ERROR:
      case ProcessorStatusType.INVALID:
        return Status.Error;
      case ProcessorStatusType.PROCESSING:
      case ProcessorStatusType.SUCCESSFUL:
        return Status.Processing;
    }

    return Status.Unknown;
  },
};

export default processStatusMapper;
