import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import { RoutePath, routes } from "./routes";
import Layout from "./layout/Layout";
import TeamMembersPage from "./pages/team-members/TeamMembersPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {routes.map((route) => (
          <Route
            index={RoutePath.HOME === route.path}
            path={route.path}
            element={<ProtectedRoute component={route.component} />}
            key={route.name}
          />
        ))}
      </Route>
      {/* Redirect non-mapped routes to home page */}
      <Route path="*" element={<TeamMembersPage />} />
    </Routes>
  );
}

export default App;
