import { ITranscriptionApiClient } from "../clients/transcriptions/ITranscriptionApiClient";
import transcriptionResponseMapper from "../clients/transcriptions/models/responses/TranscriptionResponse";
import { Transcription } from "../models/Transcription";
import { CommVisionToken } from "../security/commVisionToken";
import { ITranscriptionService } from "./ITranscriptionService";

export class TranscriptionService implements ITranscriptionService {
  constructor(
    private readonly transcriptionApiClient: ITranscriptionApiClient
  ) {}

  public async getTranscriptionByInteractionId(
    authDetails: CommVisionToken | null,
    interactionId: string
  ): Promise<Transcription | undefined> {
    const response =
      await this.transcriptionApiClient.getTranscriptionByInteractionId(
        authDetails,
        interactionId
      );

    if (!response) {
      return undefined;
    }

    return transcriptionResponseMapper.toModel(response);
  }
}
