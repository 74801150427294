import AudioPlayer from "react-h5-audio-player";
import TranscriptAgent from "../../components/TranscriptAgent";
import React from "react";
import { Interaction } from "../../models/Interaction";

interface InteractionRecordingProps {
  interaction: Interaction;
  transcriptionData: any;
  recordingUrl: string;
}

export default function InteractionRecording({
  interaction,
  transcriptionData,
  recordingUrl,
}: InteractionRecordingProps) {
  const [currentTime, setCurrentTime] = React.useState(0);
  const handleTimeUpdate = (e: any) => {
    setCurrentTime(e.target.currentTime);
  };

  const [playerRef, setPlayerRef] = React.useState<any>(null);

  const handleSeek = (time: number) => {
    //console.log("Seeking to: ", time);
    if (playerRef) {
      playerRef.audio.current.currentTime = time;
    }
  };

  return (
    <div className="flex flex-row w-full">
      <div className="w-4/6">
        <div className="p-4 pr-10">
          <AudioPlayer
            ref={(ref) => setPlayerRef(ref)}
            src={recordingUrl}
            autoPlay={false}
            onListen={handleTimeUpdate}
            onPlay={(e) => console.log("onPlay")}
            customAdditionalControls={[]}
            className="w-full p-8 border-gray-600"
          />
        </div>
      </div>
      <div className="w-2/6">
        <div className="p-4">
          <div className="transcriptViewer">
            <TranscriptAgent
              currentTime={currentTime}
              transcript={transcriptionData.transcription?.mergedDisplayWords}
              moveAudio={handleSeek}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
