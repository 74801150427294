import { Status } from "../models/ProcessesStatus";
import { Tooltip } from "react-tooltip";
import StatusContainer from "./StatusContainer";
import Avatar from "react-avatar";
import { TeamMemberWithLatestInteraction } from "../models/TeamMemberWithLatestInteraction";
import Pagination, { PaginationProps } from "./Pagination";
import SearchLg from "@untitled-ui/icons-react/build/esm/SearchLg";
import moment from "moment";
import SortableTableHeading from "./SortableTableHeading";
import { SortDirection } from "../models/SortBy";
import { DebouncedInput } from "./DebouncedInput";

interface TeamMembersListProps {
  teamMembers: TeamMemberWithLatestInteraction[];
  pagination: PaginationProps;
  sortDirection: SortDirection;
  activeColumns: string[];
  sortingColumns: string[];
  sortByColumnOnClick: (column: string, sortDirection: SortDirection) => void;
  onSearchChange: (searchPhrase: string) => void;
}

const TeamMembersList = ({
  teamMembers,
  pagination,
  sortDirection,
  activeColumns,
  sortingColumns,
  sortByColumnOnClick,
  onSearchChange,
}: TeamMembersListProps) => {
  return (
    <>
      <div className="flex mb-6">
        <div className="flex items-center justify-start w-1/2">
          {/* <button className="flex items-center justify-center p-6 h-10 text-md border-slate-300 text-gray-700 border font-bold rounded-lg">
            All Time
            <span>
              <IoClose size={28} />
            </span>
          </button> */}
        </div>
        <div className="flex items-center justify-end w-1/2">
          <div className="flex flex-row items-center border border-gray-300 rounded-md">
            <div className="p-2">
              <SearchLg />
            </div>
            <DebouncedInput
              value={""}
              onChange={onSearchChange}
              placeholder="Type to search..."
              delay={500} // Debounce delay in milliseconds
              className="w-full"
            />
          </div>
        </div>
      </div>
      <div className="">
        {/* table has light grey border around each row with first column big padding */}
        <table className="table-auto w-full text-left min-w-full divide-y divide-gray-200  border border-gray-200 rounded-lg p-3">
          <thead>
            <tr>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                <SortableTableHeading
                  headingText="Team Member"
                  headingToSort="Name"
                  sortColumnDirection={sortDirection}
                  activeColumns={activeColumns}
                  sortingColumns={sortingColumns}
                  sortByColumnOnClick={sortByColumnOnClick}
                />
              </th>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                <SortableTableHeading
                  headingText="Date"
                  headingToSort="StartDateTime"
                  sortColumnDirection={sortDirection}
                  activeColumns={activeColumns}
                  sortingColumns={sortingColumns}
                  sortByColumnOnClick={sortByColumnOnClick}
                />
              </th>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                <SortableTableHeading
                  headingText="Subject"
                  headingToSort="Subject"
                  sortColumnDirection={sortDirection}
                  activeColumns={activeColumns}
                  sortingColumns={sortingColumns}
                  sortByColumnOnClick={sortByColumnOnClick}
                />
              </th>

              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                <SortableTableHeading
                  headingText="Total Duration"
                  headingToSort="TotalDurationInSeconds"
                  sortColumnDirection={sortDirection}
                  activeColumns={activeColumns}
                  sortingColumns={sortingColumns}
                  sortByColumnOnClick={sortByColumnOnClick}
                />
              </th>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                Companies
              </th>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                Participants
              </th>

              <th className="w-[120px] px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                Process Status
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 ">
            {teamMembers.map((teamMember) => {
              return (
                <tr key={teamMember.userId}>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-bold text-gray-800 ">
                    <a href={`/team_members/${teamMember.userId}`}>
                      {teamMember.name}
                    </a>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    {moment(teamMember.startDateTime).format(
                      "MMM Do [at] h:mm a"
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-bold text-gray-800 ">
                    <a href={`/interactions/${teamMember.interactionId}`}>
                      {teamMember.subject}
                    </a>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    {moment
                      .duration(
                        Number(teamMember.totalDurationInSeconds),
                        "seconds"
                      )
                      .humanize()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    <div className="flex -space-x-2 rtl:space-x-reverse">
                      {teamMember.companies
                        .slice(0, 5)
                        .map((company, index) => (
                          <div
                            key={index + company.id}
                            className="rounded-full "
                          >
                            <Tooltip id={index + company.id} />

                            <Avatar
                              name={company.name}
                              size="30"
                              round={true}
                              textMarginRatio={0.05}
                              data-tooltip-id={index + company.id}
                              data-tooltip-content={company.name}
                              data-tooltip-place="left"
                            />
                          </div>
                        ))}

                      {teamMember.companies?.length > 5 && (
                        <div className="cursor-pointer flex items-center justify-center w-7 text-xs font-medium text-white bg-gray-700 rounded-full hover:bg-gray-600  !ml-1">
                          <span>+{teamMember.companies.length - 5}</span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    <div className="flex -space-x-2 rtl:space-x-reverse">
                      {teamMember.participants
                        .slice(0, 5)
                        .map((participant, index) => (
                          <div
                            key={index + participant.id}
                            className="rounded-full "
                          >
                            <Tooltip id={index + participant.id} />
                            <a
                              href={`/${
                                participant.isInternal
                                  ? "team_members"
                                  : "participants"
                              }/${participant.id}`}
                            >
                              <Avatar
                                name={participant.name}
                                size="30"
                                round={true}
                                textMarginRatio={0.05}
                                data-tooltip-id={index + participant.id}
                                data-tooltip-content={participant.name}
                                data-tooltip-place="left"
                              />
                            </a>
                          </div>
                        ))}

                      {teamMember.participants.length > 5 && (
                        <div className="cursor-pointer flex items-center justify-center w-7 text-xs font-medium text-white bg-gray-700 rounded-full hover:bg-gray-600  !ml-1">
                          <span>+{teamMember.participants.length - 5}</span>
                        </div>
                      )}
                    </div>
                  </td>

                  <td className="flex items-center justify-start px-6 py-4 whitespace-nowrap text-md font-medium">
                    <StatusContainer
                      status={
                        teamMember.metaData.processStatus?.status ??
                        Status.Error
                      }
                      correlationId={teamMember.metaData.correlationId}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {pagination.totalItems > pagination.itemsPerPage && (
          <Pagination
            currentPage={pagination.currentPage}
            itemsPerPage={pagination.itemsPerPage}
            totalItems={pagination.totalItems}
            onPageChange={pagination.onPageChange}
          />
        )}
      </div>
    </>
  );
};

export default TeamMembersList;
