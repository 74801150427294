import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { IoFilterOutline } from "react-icons/io5";

interface FilterMenuProps {
  filterComponent: React.ReactNode | ((close: () => void) => React.ReactNode);
  position?: "left" | "right";
}

const FilterMenu = ({
  filterComponent,
  position = "left",
}: FilterMenuProps) => {
  return (
    <Popover className="static ml-2">
      {({ close }) => (
        <>
          <PopoverButton className="inline-flex items-center gap-x-1 text-sm/6 font-semibold text-gray-900 focus:outline-none">
            <IoFilterOutline />
          </PopoverButton>
          <PopoverPanel
            className={`absolute z-50 mt-1 p-2 border-red-100 border-2 bg-white rounded-lg shadow-lg overflow-hidden ${
              position === "left" ? "left-0" : "right-0"
            }`}
          >
            {typeof filterComponent === "function"
              ? filterComponent(close)
              : filterComponent}
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};
export default FilterMenu;
