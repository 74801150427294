import { Interaction } from "../../../../models/Interaction";
import metaDataResponseMapper, { MetaDataResponse } from "./MetaDataResponse";
import thinCompanyResponseMapper from "./ThinCompanyResponse";
import thinParticipantMapper, {
  ThinParticipantResponse,
} from "./ThinParticipantResponse";

export type InteractionResponse = {
  id: string;
  customerId: string;
  subject: string;
  description?: string;
  startDateTime: Date;
  endDateTime?: Date;
  totalDurationInSeconds: number;
  participants: ThinParticipantResponse[];
  metaData: MetaDataResponse;
  organizer?: ThinParticipantResponse | boolean;
};

const interactionResponseMapper = {
  toModel(response: InteractionResponse): Interaction {
    let companies = response.participants
      .filter((x) => x.company !== undefined && x.company !== null)
      .map((x) => x.company!);

    if (companies.length > 1) {
      companies = companies.sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    return {
      id: response.id,
      customerId: response.customerId,
      subject: response.subject,
      description: response.description,
      startDateTime: response.startDateTime,
      endDateTime: response.endDateTime,
      totalDurationInSeconds: response.totalDurationInSeconds,
      participants: response.participants.map((x) =>
        thinParticipantMapper.toModel(x)
      ),
      metaData: metaDataResponseMapper.toModel(response.metaData),
      companies:
        companies?.map((x) => thinCompanyResponseMapper.toModel(x)) ?? [],
      organizer: response.participants?.find((x) => x.isOrganizer),
    };
  },
};

export default interactionResponseMapper;
