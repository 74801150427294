export enum Status {
  Started = "Started",
  Processing = "Processing",
  Error = "Error",
  Completed = "Completed",
  Unknown = "Unknown",
}

export enum ProcessorStatusType {
  UNKNOWN = "Unknown",
  STARTED = "Started",
  PROCESSING = "Processing",
  SUCCESSFUL = "Successful",
  COMPLETE = "Complete",
  ERROR = "Error",
  INVALID = "Invalid",
  COMPLETED = "Completed",
}

export enum ProcessStatusStep {
  CREATE_RAW_EVENT = "CREATE_RAW_EVENT",
  VALIDATE_MEETING_EVENT = "VALIDATE_MEETING_EVENT",
  PROCESS_MEETING = "PROCESS_MEETING",
  CREATE_MEETING = "CREATE_MEETING",
  UPDATE_MEETING = "UPDATE_MEETING",
  SAVE_MEETING_PARTICIPANTS = "SAVE_MEETING_PARTICIPANTS",
  LINK_MEETING_PARTICIPANT = "LINK_MEETING_PARTICIPANT",
  ENRICH_MEETING_PARTICIPANT = "ENRICH_MEETING_PARTICIPANT",
  CREATE_PARTICIPANT = "CREATE_PARTICIPANT",
  UPDATE_PARTICIPANT = "UPDATE_PARTICIPANT",
  COMPLETED = "COMPLETED",
}

export type ProcessStatus = {
  id: string;
  customerId: string;
  createdOn: Date;
  statusText: string;
  statusType: ProcessorStatusType;
  step: ProcessStatusStep;
  status: Status;
};
