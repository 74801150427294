import { useCallback, useEffect, useState } from 'react';
import { Integration } from '../../../models/Integration';
import { useAuthDetails } from '../../../hooks/useAuthDetails';
import { IConnectorsApiClient } from '../../../clients/connectors/IConnectorsApiClient';
import { ConnectorsApiClient } from '../../../clients/connectors/ConnectorsApiClient';
import { ConnectorsService } from '../../../services/ConnectorsService';
import { IConnectorsService } from '../../../services/IConnectorsService';
import { ConfigurationCard } from '../../../components/ConfigurationCard';

import { availableCRMIntegrationTypes, AvailableIntegrationType, availablePhoneIntegrationTypes } from '../../../clients/sharedModels/responses/AvailableIntegrationTypes';
import { LoadingCard } from '../../../components/LoadingCard';

interface IntegrationProps {
    configurationId?: string;
    crmIntegrations?: Integration[];
    phoneIntegrations?: Integration[];
}


const connectorsApiClient: IConnectorsApiClient = new ConnectorsApiClient();
const connectorsService: IConnectorsService = new ConnectorsService(
  connectorsApiClient
);

export const PhoneIntegrationsTab = (integrationProps: IntegrationProps) => {

    const {  phoneIntegrations } = integrationProps;

    const authDetails = useAuthDetails();
    const [isLoaded, setIsLoaded] = useState(false);

    const [phoneIntegrationTypes, setphoneIntegrationTypes] = useState<AvailableIntegrationType[]>([]);

    const [activeIntegration, setActiveIntegration] = useState<AvailableIntegrationType | null>(null);
    const [windowOpened, setWindowOpened] = useState<Window | null>(null);
    
    // loop through available and if matching with the connected phone, set the connected flag to true

    useEffect(() => {
        console.log(phoneIntegrations, 'phoneIntegrations')
        const phoneIntegrationsWithConnectedFlag = availablePhoneIntegrationTypes.map((integration) => {
            const connectedIntegration = phoneIntegrations?.find((connectedIntegration) => connectedIntegration.integrationDetails?.name === integration.name);
            return {
                ...integration,
                connected: !!connectedIntegration,
                integrationDetails: connectedIntegration
            };
        });

        
        //review crmIntegrations for any missing integrations that aren't in the availableCRMIntegrationTypes
        const missingIntegrations = phoneIntegrations?.filter((connectedIntegration) => {
            return !availableCRMIntegrationTypes.some((integration) => integration.name === connectedIntegration.integrationDetails?.name);
        });
        for(const missingIntegration of missingIntegrations || []) {
            phoneIntegrationsWithConnectedFlag.push({
                name: missingIntegration.integrationDetails?.name || '',
                logoUrl: missingIntegration.integrationDetails?.logoUrl || '',
                description: '',
                id: missingIntegration.id, 
                connected: true,
                integrationDetails: missingIntegration,
                commingSoon: true
            });
        }



        setphoneIntegrationTypes(phoneIntegrationsWithConnectedFlag);
        setIsLoaded(true);
    }, [phoneIntegrations]);

    // function for opening phone integration using usecallback
    const openPhoneIntegration = useCallback(async (integration: AvailableIntegrationType) => {
        setActiveIntegration(integration);
        console.log('open phone integration', integration);
        if(integration.name === 'Microsoft Teams') {
            if(!integration.integrationDetails) {
                console.log('integration details not found');
                const url = await connectorsService.consentMicrosoftIntegration(authDetails);
                if(url) setWindowOpened(window.open(url, '_blank'));
            }

        }
    }, [authDetails]);

    useEffect(() => {
        let interval: any;
        if (windowOpened) {
            // Set up interval to check if the window is closed
            interval = setInterval(() => {
                if (windowOpened.closed) {
                    console.log('window closed');
                    setActiveIntegration(null);
                    setWindowOpened(null);
                    clearInterval(interval);
                }
            }, 500) as unknown as number;
        }
        return () => (interval && clearInterval(interval));
    }, [windowOpened]);

    // open listener for windows messages from when we have opened the microsoft teams consent page
    useEffect(() => {
        const listener = async (event: MessageEvent) => {
            if(event.origin === process.env.REACT_APP_INTEGRATION_TEAM_URL) {
                console.log('message received', event.data);
                if(event.data === 'consent_granted') {
                    console.log('consent granted');
                }
            }
            setActiveIntegration(null);
        };
        window.addEventListener('message', listener);
        return () => {
            window.removeEventListener('message', listener);
        };
    }, [activeIntegration]);


    return (
        <div>
            <div className='flex justify-between mb-6 mt-4'>

        <div className="justify-start w-3/4">
                <h2 className='text-2xl font-bold mb-2 mt-4'>Integrations and connected Communication Providers</h2>
                <p>Communication is at the core of every business - use CommVision to centralise it with intelligence</p>
                
        </div>
        <div className="flex items-center justify-end w-1/4">

              </div>
              </div>
            <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {!isLoaded && <LoadingCard />}
            {isLoaded && phoneIntegrationTypes.sort((a, b) => {
                    if(a.connected && !a.commingSoon && !b.connected) {
                        return -1;
                    }
                    if(b.connected && !b.commingSoon && !a.connected) {
                        return 1;
                    }
                    if(a.commingSoon && !b.commingSoon) {
                        return 1;
                    }
                    if(b.commingSoon && !a.commingSoon) {
                        return -1;
                    }
                    return 0;
                })
                .map((integration) => {
                    return (
                        <ConfigurationCard
                            key={integration.name}
                            integrationType={integration}
                            isLoading={activeIntegration?.id === integration.id}
                            openCRM={openPhoneIntegration}
                        />
                    );
                })}
                </div>
            </div>
        </div>
    );
}