import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import InteractionDetailBar from "../../components/InteractionDetailBar";
import InteractionParticipantsList from "./InteractionParticipantsList";
import { PaginationProps } from "../../components/Pagination";
import ItemsPerPageSelector from "../../components/ItemsPerPageSelector";
import { PAGINATION_CONSTANTS } from "../../configs/constants";
import { useAuthDetails } from "../../hooks/useAuthDetails";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import BreadCrumb from "../../components/BreadCrumb";
import HomeLine from "@untitled-ui/icons-react/build/esm/HomeLine";
import InteractionRecording from "./InteractionRecording";
import { ITranscriptionService } from "../../services/ITranscriptionService";
import { TranscriptionService } from "../../services/TranscriptionService";
import { TranscriptionApiClient } from "../../clients/transcriptions/TranscriptionApiClient";
import { Transcription } from "../../models/Transcription";
import SummaryContent from "../../components/SummaryContent";
import { InteractionService } from "../../services/InteractionService";
import { IInteractionService } from "../../services/IInteractionService";
import { InteractionsApiClient } from "../../clients/interactions/InteractionsApiClient";
import { Interaction } from "../../models/Interaction";
import { ThinParticipant } from "../../models/ThinParticipant";
import { LoadingCard } from "../../components/LoadingCard";

const transcriptionService: ITranscriptionService = new TranscriptionService(
  new TranscriptionApiClient()
);

const interactionService: IInteractionService = new InteractionService(
  new InteractionsApiClient()
);

const InteractionPage = () => {
  const authDetails = useAuthDetails();
  const [loading, setLoading] = useState(false);
  const [interaction, setInteraction] = useState<Interaction | null>(null);
  //const [error, setError] = useState<string | null>(null);
  const params = useParams();
  const [id, setId] = useState<string | null>(null);

  const [transcription, setTranscription] = useState<Transcription | undefined>(
    undefined
  );

  const [interactionParticipants, setInteractionParticipants] = useState<
    ThinParticipant[]
  >([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    PAGINATION_CONSTANTS.DEFAULT_ITEMS_PER_PAGE
  );
  const [totalItems] = useState(0);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const breadCrumbs = [
    { name: "Home", link: "/", icon: HomeLine },
    { name: "Interactions", link: "/interactions" },
    {
      name: interaction?.subject || "Interaction",
      link: `/interaction/${id}`,
      active: true,
    },
  ];

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const paginationSettings: PaginationProps = {
    currentPage,
    itemsPerPage,
    totalItems,
    onPageChange: handlePageChange,
  };

  const [recordings, setRecordings] = useState<string[] | null>(null);

  const loadTranscription = useCallback(
    async (interactionId: string) => {
      if (!authDetails) return;

      try {
        const data = await transcriptionService.getTranscriptionByInteractionId(
          authDetails,
          interactionId
        );
        setTranscription(data);
        setRecordings(data?.combinedMediaUrl ? [data.combinedMediaUrl] : null);
        console.log(data, "transcription");
      } catch (e) {
        if (e instanceof Error) {
          //setError(e.message);
        }
      }
    },
    [authDetails]
  );

  const loadInteraction = useCallback(async () => {
    if (!authDetails) return;
    if (!id) return;
    console.log("Loading Interaction", id);
    try {
      setLoading(true);
      const data = await interactionService.getInteraction(authDetails, id);
      console.log(data, "interaction");
      setInteraction(data || null);
      setInteractionParticipants(data?.participants || []);
      console.log(data?.participants, "participants");
      await loadTranscription(id);
      setLoading(false);

      setTabIndex(0);

      return data;
    } catch (e) {
      if (e instanceof Error) {
        //setError(e.message);
      }
      setLoading(false);
    }
  }, [authDetails, id, loadTranscription]);

  useEffect(() => {
    if (!params?.id) return;
    setId(params?.id ?? "");
    setLoading(true);
    loadInteraction();
  }, [loadInteraction, loadTranscription, params]);

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div>
      <>
        {loading && (
          <div className="center-page">
            <span className="spinner primary"></span>
            <LoadingCard />
          </div>
        )}

        {interaction && (
          <>
            <BreadCrumb items={breadCrumbs} />
            <h1 className="text-4xl capitalize font-bold">
              {interaction.subject}
            </h1>
            <InteractionDetailBar
              interaction={interaction}
              interactionParticipants={interactionParticipants}
            />
          </>
        )}

        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            {transcription && <Tab>Summary</Tab>}
            {recordings && recordings.length > 0 && (
              <Tab>Recording & Transcript</Tab>
            )}
            <Tab>Attendees</Tab>
          </TabList>
          {transcription && (
            <TabPanel>
              <div className="mt-5 pt-2">
                <SummaryContent
                  summaryText={
                    transcription?.transcription?.conversationSummary ?? ""
                  }
                />
              </div>
            </TabPanel>
          )}
          {recordings && recordings.length > 0 && (
            <TabPanel>
              <InteractionRecording
                interaction={interaction!}
                transcriptionData={transcription}
                recordingUrl={recordings[0]}
              />
            </TabPanel>
          )}
          <TabPanel>
            {totalItems > PAGINATION_CONSTANTS.DEFAULT_ITEMS_PER_PAGE && (
              <ItemsPerPageSelector
                itemsPerPage={itemsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                maxItemsPerPage={25}
              />
            )}
            {interactionParticipants && interactionParticipants.length > 0 && (
              <div className="pt-4">
                <InteractionParticipantsList
                  interactionParticipants={interactionParticipants}
                  pagination={
                    totalItems > PAGINATION_CONSTANTS.DEFAULT_ITEMS_PER_PAGE
                      ? paginationSettings
                      : undefined
                  }
                />
              </div>
            )}
          </TabPanel>
        </Tabs>
      </>
    </div>
  );
};

export default InteractionPage;
