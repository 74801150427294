import axios from "axios";
import { logger } from "../../infrastructure/logger";
import { InteractionResponse } from "./models/responses/InteractionResponse";
import { TeamMemberWithLatestInteractionResponse } from "./models/responses/TeamMemberWithLatestInteractionResponse";

import { SortBy } from "../../models/SortBy";
import { SortByRequest } from "../sharedModels/requests/SortByRequest";
import { IInteractionsApiClient } from "./IInteractionsApiClient";
import { CommVisionToken } from "../../security/commVisionToken";
import { SearchRequest } from "../sharedModels/requests/SearchRequest";
import { Search } from "../../models/Search";
import { Participant } from "./models/Participant";
import {
  PaginationResponse,
  notFoundPaginationResponse,
} from "./models/responses/PaginationResponse";
import { ParticipantsResponse } from "./models/responses/ParticipantsResponse";
import { DateRange } from "../../models/DateRange";

export class InteractionsApiClient implements IInteractionsApiClient {
  private BASE_URL = process.env.REACT_APP_MEETINGS_API_URL + "/v1";

  public async getTeamMembersWithInteractions(
    authDetails: CommVisionToken | null,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<PaginationResponse<TeamMemberWithLatestInteractionResponse>> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("get team members with latest interactions", {
      customerId,
      sortBy,
    });

    let sort;

    if (sortBy) {
      sort = SortByRequest.ConvertFrom(sortBy);
    }

    const result = await axios.get(
      `${this.BASE_URL}/teammembers/interactions`,
      {
        params: { customerId, page, pageSize, sortby: sort },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (result.status === 200) {
      return result.data;
    } else if (result.status === 404) {
      return notFoundPaginationResponse;
    } else {
      throw new Error(
        `Failed to get team members with latest interactions: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async searchTeamMembersWithInteractions(
    authDetails: CommVisionToken | null,
    search: Search,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<PaginationResponse<TeamMemberWithLatestInteractionResponse>> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("search team members with latest interactions", {
      customerId,
      sortBy,
      search,
    });

    let sort;

    if (sortBy) {
      sort = SortByRequest.ConvertFrom(sortBy);
    }

    const result = await axios.get(
      `${this.BASE_URL}/teammembers/interactions`,
      {
        params: {
          customerId,
          page,
          pageSize,
          sortby: sort,
          search: SearchRequest.ConvertFrom(search),
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (result.status === 200) {
      return result.data;
    } else if (result.status === 404) {
      return notFoundPaginationResponse;
    } else {
      throw new Error(
        `Failed to search team members with latest interactions: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async getInteractions(
    authDetails: CommVisionToken | null,
    page: number,
    pageSize: number,
    sortBy?: SortBy[],
    dateRange?: DateRange
  ): Promise<PaginationResponse<InteractionResponse>> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("get touch points", {
      customerId,
      page,
      pageSize,
      sortBy,
      dateRange,
    });

    let sort;

    if (sortBy) {
      sort = SortByRequest.ConvertFrom(sortBy);
    }

    let from, to;

    if (dateRange) {
      from = dateRange.from?.toISOString();
      to = dateRange.to?.toISOString();
    }

    const result = await axios.get(`${this.BASE_URL}/interactions`, {
      params: { customerId, page, pageSize, sortBy: sort, from, to },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (result.status === 200) {
      return result.data;
    } else if (result.status === 404) {
      return notFoundPaginationResponse;
    } else {
      throw new Error(
        `Failed to get touch points: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async getInteractionsForParticipant(
    authDetails: CommVisionToken | null,
    participantId: string,
    filterByParticipantIsOrganizer: boolean,
    page: number,
    pageSize: number,
    sortBy?: SortBy[],
    dateRange?: DateRange,
    filterByAdditionalParticipantId?: string
  ): Promise<PaginationResponse<InteractionResponse>> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("get touch points for participant", {
      customerId,
      participantId,
      page,
      pageSize,
      sortBy,
      isOrganizer: filterByParticipantIsOrganizer,
      dateRange,
      filterByAdditionalParticipantId,
    });

    let sort;

    if (sortBy) {
      sort = SortByRequest.ConvertFrom(sortBy);
    }

    let from, to;

    if (dateRange) {
      from = dateRange.from?.toISOString();
      to = dateRange.to?.toISOString();
    }

    const result = await axios.get(
      `${this.BASE_URL}/participants/${participantId}/interactions`,
      {
        params: {
          customerId,
          page,
          pageSize,
          sortBy: sort,
          isOrganizer: filterByParticipantIsOrganizer,
          from,
          to,
          participantId: filterByAdditionalParticipantId,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (result.status === 200) {
      return result.data;
    } else if (result.status === 404) {
      return notFoundPaginationResponse;
    } else {
      throw new Error(
        `Failed to get touch points for participant: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async getInteraction(
    authDetails: CommVisionToken | null,
    id: string
  ): Promise<InteractionResponse | undefined> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("get touch point by id", { customerId, id });

    const result = await axios.get(`${this.BASE_URL}/interactions/${id}`, {
      params: { customerId },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (result.status === 200) {
      return result.data;
    } else if (result.status === 404) {
      return undefined;
    } else {
      throw new Error(
        `Failed to get touch point by id: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async searchInteractions(
    authDetails: CommVisionToken | null,
    search: Search,
    page: number,
    pageSize: number,
    sortBy?: SortBy[],
    dateRange?: DateRange
  ): Promise<PaginationResponse<InteractionResponse>> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("search touch points by subject", {
      customerId,
      page,
      pageSize,
      sortBy,
      search,
      dateRange,
    });

    let sort;

    if (sortBy) {
      sort = SortByRequest.ConvertFrom(sortBy);
    }

    let from, to;

    if (dateRange) {
      from = dateRange.from?.toISOString();
      to = dateRange.to?.toISOString();
    }

    const result = await axios.get(`${this.BASE_URL}/interactions`, {
      params: {
        customerId,
        page,
        pageSize,
        sortBy: sort,
        search: SearchRequest.ConvertFrom(search),
        from,
        to,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (result.status === 200) {
      return result.data;
    } else if (result.status === 404) {
      return notFoundPaginationResponse;
    } else {
      throw new Error(
        `Failed to search touch points by subject: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async searchInteractionsForParticipant(
    authDetails: CommVisionToken | null,
    participantId: string,
    filterByParticipantIsOrganizer: boolean,
    search: Search,
    page: number,
    pageSize: number,
    sortBy?: SortBy[],
    dateRange?: DateRange
  ): Promise<PaginationResponse<InteractionResponse>> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("search touch points for participant", {
      customerId,
      participantId,
      page,
      pageSize,
      sortBy,
      search,
      isOrganizer: filterByParticipantIsOrganizer,
      dateRange,
    });

    let sort;

    if (sortBy) {
      sort = SortByRequest.ConvertFrom(sortBy);
    }

    let from, to;

    if (dateRange) {
      from = dateRange.from?.toISOString();
      to = dateRange.to?.toISOString();
    }

    const result = await axios.get(
      `${this.BASE_URL}/participants/${participantId}/interactions`,
      {
        params: {
          customerId,
          page,
          pageSize,
          sortBy: sort,
          search: SearchRequest.ConvertFrom(search),
          isOrganizer: filterByParticipantIsOrganizer,
          from,
          to,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (result.status === 200) {
      return result.data;
    } else if (result.status === 404) {
      return notFoundPaginationResponse;
    } else {
      throw new Error(
        `Failed to search touch points for participant: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async getParticipant(
    authDetails: CommVisionToken | null,
    id: string
  ): Promise<Participant> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("get participant", { id, customerId });

    const result = await axios.get(`${this.BASE_URL}/participants/${id}`, {
      params: { customerId },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to get participant by Id: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async searchParticipants(
    authDetails: CommVisionToken | null,
    search: Search,
    ignoreInternalParticipants: boolean,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<ParticipantsResponse> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("search participants", {
      customerId,
      page,
      pageSize,
      sortBy,
      search,
    });

    let sort;

    if (sortBy) {
      sort = SortByRequest.ConvertFrom(sortBy);
    }

    const result = await axios.get(`${this.BASE_URL}/participants`, {
      params: {
        customerId,
        page,
        pageSize,
        sortBy: sort,
        search: SearchRequest.ConvertFrom(search),
        ignoreInternalParticipants,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to search participants: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async getParticipants(
    authDetails: CommVisionToken | null,
    ignoreInternalParticipants: boolean,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<ParticipantsResponse> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("get participants", { customerId, page, pageSize, sortBy });

    let sort;

    if (sortBy) {
      sort = SortByRequest.ConvertFrom(sortBy);
    }

    const result = await axios.get(`${this.BASE_URL}/participants`, {
      params: {
        customerId,
        page,
        pageSize,
        sortBy: sort,
        ignoreInternalParticipants,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to get participants: ${result.status} - ${result.statusText}`
      );
    }
  }
}
