import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Participant } from "../../clients/interactions/models/Participant";
import { PaginationProps } from "../../components/Pagination";
import { useAuthDetails } from "../../hooks/useAuthDetails";
import HomeLine from "@untitled-ui/icons-react/build/esm/HomeLine";
import BreadCrumb from "../../components/BreadCrumb";
import ParticipantDetailBar from "../../components/ParticipantDetailBar";
import InteractionsList, {
  DateRangeFilterProps,
  ParticipantFilterProps,
  SortColumnProps,
} from "../../components/InteractionsList";
import { Interaction } from "../../models/Interaction";
import { SortDirection } from "../../models/SortBy";
import { IInteractionService } from "../../services/IInteractionService";
import { InteractionsApiClient } from "../../clients/interactions/InteractionsApiClient";
import { InteractionService } from "../../services/InteractionService";
import { LoadingCard } from "../../components/LoadingCard";
import { Search } from "../../models/Search";
import { Range } from "react-date-range";
import moment from "moment";

const interactionService: IInteractionService = new InteractionService(
  new InteractionsApiClient()
);

function ParticipantPage() {
  const authDetails = useAuthDetails();
  const [loading, setLoading] = useState(false);
  const [participant, setParticipant] = useState<Participant | null>(null);
  const [error, setError] = useState<string | null>(null);
  const params = useParams();
  const id = params.id!;

  const defaultItemsPerPage = 25;
  const defaultSortingColumn = "Date";
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [resultsAreFiltered, setResultsAreFiltered] = useState(false);
  const [interactions, setInteractions] = useState<Interaction[]>([]);
  const [sortingColumn, setSortingColumn] = useState(defaultSortingColumn);
  const [activeColumn, setActiveColumn] = useState(defaultSortingColumn);
  const [sortingDirection, setSortingDirection] = useState<SortDirection>(
    SortDirection.Ascending
  );
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [dateRangeFilter, setDateRangeFilter] = useState<Range>();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginationSettings: PaginationProps = {
    currentPage,
    itemsPerPage,
    totalItems,
    onPageChange: handlePageChange,
  };

  const breadCrumbs = [
    { name: "Home", link: "/", icon: HomeLine },
    { name: "Participants", link: "/participants" },
    {
      name: participant?.name || participant?.email || "Participant",
      link: `/participants/${id}`,
      active: true,
    },
  ];

  const loadInteractions = useCallback(
    async (sortByColumn?: string, sortByDirection?: SortDirection) => {
      if (!authDetails) return;

      const sortBy =
        sortByColumn && sortByDirection
          ? [{ propertyName: sortByColumn, direction: sortByDirection }]
          : undefined;

      const dateRange = dateRangeFilter
        ? { from: dateRangeFilter.startDate, to: dateRangeFilter.endDate }
        : undefined;

      const data = await interactionService.getInteractionsForParticipant(
        authDetails,
        id,
        false,
        currentPage,
        itemsPerPage,
        sortBy,
        dateRange
      );

      if (!data?.items) {
        return;
      }

      setInteractions(data.items || []);
      setTotalItems(data.totalCount || 0);
      setResultsAreFiltered(false);
      setSearchText(undefined);
    },
    [authDetails, currentPage, itemsPerPage, id, dateRangeFilter]
  );

  const loadFilteredInteractions = useCallback(
    async (
      searchFor: string,
      sortByColumn?: string,
      sortByDirection?: SortDirection
    ) => {
      if (!authDetails) return false;

      const sortBy =
        sortByColumn && sortByDirection
          ? [{ propertyName: sortByColumn, direction: sortByDirection }]
          : undefined;

      const search: Search = {
        phrase: searchFor,
        searchOn: ["Subject"],
      };

      const dateRange = dateRangeFilter
        ? { from: dateRangeFilter.startDate, to: dateRangeFilter.endDate }
        : undefined;

      const data = await interactionService.searchInteractionsForParticipant(
        authDetails,
        id,
        false,
        search,
        currentPage,
        itemsPerPage,
        sortBy,
        dateRange
      );

      if (data.totalCount > totalItems) {
        setItemsPerPage(itemsPerPage);
      }

      setInteractions(data.items || []);
      setTotalItems(data.totalCount || 0);
      setResultsAreFiltered(true);
      setSearchText(searchFor);

      if (!sortBy) {
        setActiveColumn("");
        setSortingColumn("");
      }
    },
    [authDetails, currentPage, totalItems, itemsPerPage, id, dateRangeFilter]
  );

  const loadParticipant = useCallback(async () => {
    if (!authDetails) return;

    try {
      const data = await interactionService.getParticipant(authDetails, id);
      setParticipant(data);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  }, [authDetails, id]);

  const onSearchChange = useCallback(
    async (searchFor: string | undefined) => {
      if (!authDetails) return;

      if (searchFor) {
        await loadFilteredInteractions(searchFor);
      } else {
        await loadInteractions(id);
        setSearchText(undefined);
        setActiveColumn("");
        setSortingColumn("");
      }
    },
    [authDetails, loadFilteredInteractions, loadInteractions, id]
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([loadParticipant(), loadInteractions(id)])
      .catch((e) => {
        if (e instanceof Error) {
          setError(e.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loadParticipant, loadInteractions, id]);

  const sortByColumnOnClick = (column: string) => {
    if (sortingColumn?.includes(column)) {
      if (resultsAreFiltered) {
        loadFilteredInteractions(searchText!, column, SortDirection.Descending);
      } else {
        loadInteractions(column, SortDirection.Descending);
      }

      setSortingColumn("");
      setSortingDirection(SortDirection.Descending);
    } else {
      if (resultsAreFiltered) {
        loadFilteredInteractions(searchText!, column, SortDirection.Ascending);
      } else {
        loadInteractions(column, SortDirection.Ascending);
      }

      setSortingColumn(`${column}`);
      setSortingDirection(SortDirection.Ascending);
    }

    setActiveColumn(`${column}`);
  };

  const onSetDateRangeClick = useCallback(
    async (dateRange: Range, close: () => void) => {
      setDateRangeFilter(dateRange);
      close();
    },
    []
  );

  const participantFilter: ParticipantFilterProps = {
    participants: [],
    onParticipantSearchChange: () => {},
    onParticipantFilterSetClick: () => {},
    participantsSearchText: undefined,
    filteredParticipantId: undefined,
    onParticipantClearFilterClick: () => {},
  };

  const sortColumns: SortColumnProps = {
    sortingDirection,
    activeColumns: [activeColumn],
    sortingColumns: [sortingColumn],
    sortByColumnOnClick,
  };

  const dateRangeFiltering: DateRangeFilterProps = {
    onSetDateRangeClick,
    initialFilterDateRange: dateRangeFilter ?? {
      startDate: moment().subtract(3, "months").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  };

  return (
    <div>
      {participant && (
        <>
          <BreadCrumb items={breadCrumbs} />
          <ParticipantDetailBar participant={participant} />

          <div className="mt-5">
            {error && (
              <div className="row">
                <div className="card large error">
                  <section>
                    <p>
                      <span className="icon-alert inverse "></span>
                      {error}
                    </p>
                  </section>
                </div>
              </div>
            )}

            <InteractionsList
              interactions={interactions}
              pagination={paginationSettings}
              sortColumns={sortColumns}
              onSearchChange={onSearchChange}
              dateRangeFilter={dateRangeFiltering}
              participantFilter={participantFilter}
            />

            {loading && (
              <div className="center-page">
                <span className="spinner primary"></span>
                <LoadingCard />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ParticipantPage;
