import axios from "axios";
import { logger } from "../../infrastructure/logger";
import { CommVisionToken } from "../../security/commVisionToken";
import { TranscriptionResponse } from "./models/responses/TranscriptionResponse";
import { ITranscriptionApiClient } from "./ITranscriptionApiClient";

export class TranscriptionApiClient implements ITranscriptionApiClient {
  private BASE_URL = process.env.REACT_APP_TRANSCRIPTIONS_API_URL + "/v1";

  public async getTranscriptionByInteractionId(
    authDetails: CommVisionToken | null,
    interactionId: string
  ): Promise<TranscriptionResponse | undefined> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("get transcription for interaction", { customerId });

    const result = await axios.get(`${this.BASE_URL}/transcription`, {
      params: { meetingId: interactionId },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (result.status === 200) {
      return result.data;
    } else if (result.status === 404) {
      return undefined;
    } else {
      throw new Error(
        `Failed to get transcription for interaction: ${result.status} - ${result.statusText}`
      );
    }
  }
}
