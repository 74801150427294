import axios from "axios";
import { logger } from "../../infrastructure/logger";
import { CommVisionToken } from "../../security/commVisionToken";
import { CustomerConfigurationResponse } from "../sharedModels/responses/CustomerConfigurationResponse";
import { ICustomersApiClient } from "./ICustomersApiClient";
import { ConfigurationType } from "../../models/ConfigurationType";
import { getIntegrationDetails } from "../../models/Integration";

export class CustomersApiClient implements ICustomersApiClient {
  private BASE_URL = process.env.REACT_APP_CUSTOMER_API_URL + "/v1";
  private CUSTOMER_CONFIGURATION_URL = `${this.BASE_URL}/configurations`;

  public async getCustomerConfiguration(
    authDetails: CommVisionToken | null
  ): Promise<CustomerConfigurationResponse | undefined> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("getting customer configuration", {
      customerId,
    });

    const result = await axios.get(this.CUSTOMER_CONFIGURATION_URL, {
      params: { customerId },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (result.status === 200) {
      const customerConfiguration = result.data;
      const sortedIntegrations = await this.sortCustomerIntegrations(
        customerConfiguration
      );

      return {
        ...customerConfiguration,
        crmIntegrations: sortedIntegrations.crmIntegrations,
        phoneIntegrations: sortedIntegrations.interactionIntegrations,
      };

      // TODO: HANDLE ERRORS TO DISPLAY IN FRONT END
    } else {
      throw new Error(
        `Failed to get customer configuration: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async sortCustomerIntegrations(
    customerConfiguration: CustomerConfigurationResponse
  ) {
    const { integrations } = customerConfiguration;

    //find all matching types first and then leave the rest in externalIntegrations
    const crmIntegrations = integrations.filter(
      (integration) => integration.configurationType === ConfigurationType.Merge
    );

    const interactionIntegrations = integrations.filter(
      (integration) => integration.configurationType === ConfigurationType.Phone
    );

    //for loop but with a key too
    let count = 0;
    for (const integration of crmIntegrations) {
      // check for the type of CRM and assign the logo
      const integrationType = await getIntegrationDetails(integration.name);
      if (integrationType) {
        integration.integrationDetails = integrationType;
      }
      crmIntegrations[count] = integration;
      count++;
    }

    return { crmIntegrations, interactionIntegrations };
  }
}
