import { useCallback, useEffect, useState } from "react";
import { InteractionsApiClient } from "../../clients/interactions/InteractionsApiClient";
import { useAuthDetails } from "../../hooks/useAuthDetails";
import { logger } from "../../infrastructure/logger";
import { Interaction } from "../../models/Interaction";
import { PaginationProps } from "../../components/Pagination";
import BreadCrumb from "../../components/BreadCrumb";
import HomeLine from "@untitled-ui/icons-react/build/esm/HomeLine";
import { SortDirection } from "../../models/SortBy";
import { IInteractionService } from "../../services/IInteractionService";
import { InteractionService } from "../../services/InteractionService";
import { Search } from "../../models/Search";
import { LoadingCard } from "../../components/LoadingCard";
import InteractionsList, {
  DateRangeFilterProps,
  ParticipantFilterProps,
  SortColumnProps,
} from "../../components/InteractionsList";
import { Range } from "react-date-range";
import { Participant } from "../../clients/interactions/models/Participant";
import { Results } from "../../models/Results";
import moment from "moment";

const interactionService: IInteractionService = new InteractionService(
  new InteractionsApiClient()
);

const InteractionsPage = () => {
  const defaultItemsPerPage = 25;
  const defaultSortingColumn = "Date";

  const authDetails = useAuthDetails();
  const [loading, setLoading] = useState(false);
  const [resultsAreFiltered, setResultsAreFiltered] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [interactions, setInteractions] = useState<Interaction[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [activeColumn, setActiveColumn] = useState(defaultSortingColumn);
  const [sortingColumn, setSortingColumn] = useState(defaultSortingColumn);
  const [sortingDirection, setSortingDirection] = useState<SortDirection>(
    SortDirection.Ascending
  );
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

  const [dateRangeFilter, setDateRangeFilter] = useState<Range>();

  const [participants, setParticipants] = useState<Participant[]>([]);

  const [participantsSearchText, setParticipantsSearchText] = useState<
    string | undefined
  >(undefined);

  const [filteredParticipantId, setFilteredParticipantId] = useState<
    string | undefined
  >(undefined);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginationSettings: PaginationProps = {
    currentPage,
    itemsPerPage,
    totalItems,
    onPageChange: handlePageChange,
  };

  const loadInteractions = useCallback(
    async (sortByColumn?: string, sortByDirection?: SortDirection) => {
      if (!authDetails) return;

      const sortBy =
        sortByColumn && sortByDirection
          ? [{ propertyName: sortByColumn, direction: sortByDirection }]
          : undefined;

      const dateRange = dateRangeFilter
        ? { from: dateRangeFilter.startDate, to: dateRangeFilter.endDate }
        : undefined;

      let data: Results<Interaction>;

      if (!filteredParticipantId) {
        data = await interactionService.getInteractions(
          authDetails,
          currentPage,
          itemsPerPage,
          sortBy,
          dateRange
        );
      } else {
        data = data = await interactionService.getInteractionsForParticipant(
          authDetails,
          filteredParticipantId!,
          false,
          currentPage,
          itemsPerPage,
          sortBy,
          dateRange
        );
      }

      if (!data?.items) {
        return;
      }

      setInteractions(data.items || []);
      setTotalItems(data.totalCount || 0);
      setResultsAreFiltered(false);
      setSearchText(undefined);
    },
    [
      authDetails,
      currentPage,
      itemsPerPage,
      dateRangeFilter,
      filteredParticipantId,
    ]
  );

  const loadFilteredInteractions = useCallback(
    async (
      searchFor: string,
      sortByColumn?: string,
      sortByDirection?: SortDirection
    ) => {
      if (!authDetails) return false;

      const sortBy =
        sortByColumn && sortByDirection
          ? [{ propertyName: sortByColumn, direction: sortByDirection }]
          : undefined;

      const search: Search = {
        phrase: searchFor,
        searchOn: ["Subject"],
      };

      const dateRange = dateRangeFilter
        ? { from: dateRangeFilter.startDate, to: dateRangeFilter.endDate }
        : undefined;

      const data = await interactionService.searchInteractions(
        authDetails,
        search,
        currentPage,
        itemsPerPage,
        sortBy,
        dateRange
      );

      if (data.totalCount > totalItems) {
        setItemsPerPage(itemsPerPage);
      }

      setInteractions(data.items || []);
      setTotalItems(data.totalCount || 0);
      setResultsAreFiltered(true);
      setSearchText(searchFor);

      if (!sortBy) {
        setActiveColumn("");
        setSortingColumn("");
      }
    },
    [authDetails, currentPage, totalItems, itemsPerPage, dateRangeFilter]
  );

  const onSearchChange = useCallback(
    async (searchFor: string | undefined) => {
      if (!authDetails) return;

      if (searchFor) {
        await loadFilteredInteractions(searchFor);
      } else {
        await loadInteractions();
        setSearchText(undefined);
        setActiveColumn("");
        setSortingColumn("");
      }
    },
    [authDetails, loadFilteredInteractions, loadInteractions]
  );

  const loadParticipants = useCallback(async () => {
    const sortBy = [
      { propertyName: "CreatedOn", direction: SortDirection.Descending },
    ];

    const ignoreInternalParticipants = false;

    const data = await interactionService.getParticipants(
      authDetails,
      ignoreInternalParticipants,
      1, // currentPage,
      10, //itemsPerPage,
      sortBy
    );

    if (!data?.results) {
      return;
    }

    setParticipants(data.results || []);
  }, [authDetails]);

  const loadFilteredParticipants = useCallback(
    async (searchFor: string) => {
      const sortBy = [
        { propertyName: "CreatedOn", direction: SortDirection.Descending },
      ];

      const search: Search = {
        phrase: searchFor,
        searchOn: ["Name", "Email", "Company.Name"],
      };

      const ignoreInternalParticipants = false;

      const data = await interactionService.searchParticipants(
        authDetails,
        search,
        ignoreInternalParticipants,
        1, // currentPage,
        10, //itemsPerPage,
        sortBy
      );

      if (!data?.results) {
        return;
      }

      setParticipants(data.results || []);
      setParticipantsSearchText(searchFor);
    },
    [authDetails]
  );

  useEffect(() => {
    setLoading(true);
    loadParticipants();
    loadInteractions()
      .catch((e) => {
        if (e instanceof Error) {
          setError(e.message);
          logger.error("loadInteractions", { error: e });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loadInteractions, loadParticipants]);

  const breadCrumbs = [
    { name: "Home", link: "/", icon: HomeLine },
    { name: "Interactions", link: "/interactions" },
  ];

  const sortByColumnOnClick = (column: string) => {
    if (sortingColumn?.includes(column)) {
      if (resultsAreFiltered) {
        loadFilteredInteractions(searchText!, column, SortDirection.Descending);
      } else {
        loadInteractions(column, SortDirection.Descending);
      }

      setSortingColumn("");
      setSortingDirection(SortDirection.Descending);
    } else {
      if (resultsAreFiltered) {
        loadFilteredInteractions(searchText!, column, SortDirection.Ascending);
      } else {
        loadInteractions(column, SortDirection.Ascending);
      }

      setSortingColumn(`${column}`);
      setSortingDirection(SortDirection.Ascending);
    }

    setActiveColumn(`${column}`);
  };

  const onSetDateRangeClick = useCallback(
    async (dateRange: Range, close: () => void) => {
      setDateRangeFilter(dateRange);
      close();
    },
    []
  );

  const onParticipantSearchChange = useCallback(
    async (searchFor: string | undefined) => {
      if (searchFor) {
        await loadFilteredParticipants(searchFor);
      } else {
        await loadParticipants();
        setParticipantsSearchText(undefined);
      }
    },
    [loadFilteredParticipants, loadParticipants]
  );

  const onParticipantFilterSetClick = useCallback(
    async (participantId: string, close: () => void) => {
      setFilteredParticipantId(participantId);
      setParticipantsSearchText(participantsSearchText);
      close();
    },
    [participantsSearchText]
  );

  const onParticipantClearFilterClick = useCallback(
    async (close: () => void) => {
      setFilteredParticipantId(undefined);
      setParticipantsSearchText(undefined);
      close();
    },
    []
  );

  const participantFiltering: ParticipantFilterProps = {
    participants,
    onParticipantSearchChange,
    onParticipantFilterSetClick,
    participantsSearchText,
    filteredParticipantId,
    onParticipantClearFilterClick,
  };

  const sortColumns: SortColumnProps = {
    sortingDirection,
    activeColumns: [activeColumn],
    sortingColumns: [sortingColumn],
    sortByColumnOnClick,
  };

  const dateRangeFiltering: DateRangeFilterProps = {
    onSetDateRangeClick,
    initialFilterDateRange: dateRangeFilter ?? {
      startDate: moment().subtract(3, "months").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  };

  return (
    <div>
      <BreadCrumb items={breadCrumbs} />
      <h1 className="text-4xl capitalize font-bold mb-8">
        Latest Interactions
      </h1>
      <div className="mt-5">
        {error && (
          <div className="row">
            <div className="card large error">
              <section>
                <p>
                  <span className="icon-alert inverse "></span>
                  {error}
                </p>
              </section>
            </div>
          </div>
        )}

        <InteractionsList
          interactions={interactions}
          pagination={paginationSettings}
          sortColumns={sortColumns}
          onSearchChange={onSearchChange}
          dateRangeFilter={dateRangeFiltering}
          participantFilter={participantFiltering}
        />

        {loading && (
          <div className="center-page">
            <span className="spinner primary"></span>
            <LoadingCard />
          </div>
        )}
      </div>
    </div>
  );
};

export default InteractionsPage;
