// create a module named StatusContainer.tsx that can output tailwind styled components based on status text

import React, { useState } from "react";
import { Tooltip } from "react-tooltip";

import { IoTimeOutline as InProgressIcon } from "react-icons/io5";
import { BiSolidError as ErrorIcon } from "react-icons/bi";
import { LiaHourglassStartSolid as StartedIcon } from "react-icons/lia";
import { TiTick as CompletedIcon } from "react-icons/ti";

interface StatusContainerInterface {
  status: string;
  correlationId?: string;
}

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (content: string) => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
    } catch (error) {
      setIsCopied(false);
    }
  };

  return { isCopied, copyToClipboard };
};

const StatusContainer: React.FC<StatusContainerInterface> = ({
  status,
  correlationId,
}) => {
  // let statusColour = "gray";
  let statusText = "Started";
  let statusIcon = <StartedIcon color="#2e71b3" size={30} />;
  let statusTextStyle = "font-medium text-[#2e71b3]";
  let statusTooltipText = "The interaction has started";

  switch (status) {
    case "Completed":
      // statusColour = "bg-[#d0ecd1] border-[#68bc6e] text-[#68bc6e]";
      statusText = "Completed";
      statusIcon = <CompletedIcon color="#68bc6e" size={30} />;
      statusTextStyle = "font-medium text-[#68bc6e]";
      statusTooltipText = "The interaction has successfully been processed";
      break;
    case "Processing":
      // statusColour = "bg-[#e6e1c4] border-[#be8831] text-[#be8831]";
      statusText = "Processing";
      statusIcon = <InProgressIcon color="#be8831" size={30} />;
      statusTextStyle = "font-medium text-[#be8831]";
      statusTooltipText = "The interaction has finished and is being processed";
      break;
    case "Started":
      // statusColour = "bg-[#c3d9ef] border-[#2e71b3] text-[#2e71b3]";
      statusText = "Started";
      statusIcon = <StartedIcon color="#2e71b3" size={30} />;
      statusTextStyle = "font-medium text-[#2e71b3]";
      statusTooltipText = "The interaction has started";
      break;
    case "Error":
    default:
      // statusColour = "bg-[#e6bbb7] border-[#de5f53] text-[#c23427]";
      statusText = "Warning";
      statusIcon = <ErrorIcon color="#DBA800" size={30} />;
      statusTextStyle = "font-medium text-[#DBA800]";
      statusTooltipText =
        "Processing of the interaction has finished with some errors";
      break;
  }

  const { copyToClipboard } = useCopyToClipboard();

  return (
    <div>
      <Tooltip id={correlationId} />
      {/* <div
        className={`${statusColour} text-center min-w-24 pl-3 pr-3 p-1 text-sm rounded-lg border`}
        data-tooltip-id={correlationId}
        data-tooltip-content={`CorrelationId: ${correlationId}`}
        onClick={() => copyToClipboard(correlationId ?? "unknown")}
      >
        {status !== "Unknown" ? status : null}
      </div> */}
      <div className="col">
        <div className="flex items-center gap-2">
          <div className="icon">{statusIcon}</div>
          <div className={statusTextStyle}>
            <div
              data-tooltip-id={correlationId}
              data-tooltip-content={statusTooltipText}
              onClick={() => copyToClipboard(correlationId ?? "unknown")}
            >
              {statusText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusContainer;
