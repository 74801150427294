import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { Tooltip } from "react-tooltip";
// import react-icons
import {
  IoBriefcaseOutline,
  IoCalendar,
  IoTimeOutline,
  IoVideocamOutline,
} from "react-icons/io5";
import moment from "moment";
import { Interaction } from "../models/Interaction";
import { ThinParticipant } from "../models/ThinParticipant";

interface InteractionDetailProps {
  interaction: Interaction;
  interactionParticipants: ThinParticipant[];
}

export default function InteractionDetailBar({
  interaction,
  interactionParticipants,
}: InteractionDetailProps) {
  // convert totalDurationInSeconds to human readable format ie 10 min
  const interactionDuration = moment
    .duration(Number(interaction.totalDurationInSeconds), "seconds")
    .humanize();

  return (
    <div className="flex flex-cols-6 gap-8 justify-start content-start align-middle text-xl mt-8 mb-8 w-[80vw]">
      {!interaction.endDateTime && (
        <div className="col">
          <div className="flex items-center gap-2">
            <div className="icon">
              <IoTimeOutline color="green" />
            </div>
            <div className="font-medium text-green-600">
              <div>In progress</div>
            </div>
          </div>
        </div>
      )}
      {interaction.organizer && (
        <>
          <div className="col">
            <Link to={"/participants/" + interaction.organizer?.id}>
              <div className="flex items-center gap-2">
                <Avatar
                  name={interaction.organizer?.name}
                  size="30"
                  round={true}
                  textMarginRatio={0.05}
                />
                <div className="font-medium text-grey-600">
                  <div>{interaction.organizer?.name}</div>
                </div>
              </div>
            </Link>
          </div>
          {interaction.organizer?.company && (
            <div className="col">
              <div className="flex items-center gap-2">
                <div className="icon">
                  <IoBriefcaseOutline />
                </div>
                <div className="font-medium text-grey-600">
                  <div>{interaction.organizer?.company?.name}</div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div className="col">
        <div className="flex -space-x-2 rtl:space-x-reverse">
          {interactionParticipants.slice(0, 5).map((participant, index) => (
            <div className="rounded-full border-white border-2" key={index}>
              <Tooltip id={index + participant.id} />

              <Avatar
                name={participant.name}
                size="30"
                round={true}
                textMarginRatio={0.05}
                data-tooltip-id={index + participant.id}
                data-tooltip-content={participant.name}
                data-tooltip-place="left"
              />
            </div>
          ))}
          {interactionParticipants.length > 5 && (
            <div className="flex items-center justify-center w-7 text-xs font-medium text-white bg-gray-700 rounded-full hover:bg-gray-600  !ml-1 cursor-pointer">
              <span>+{interactionParticipants.length - 5}</span>
            </div>
          )}
        </div>
      </div>
      <div className="col">
        <div className="flex items-center gap-2">
          <div className="icon">
            <IoCalendar />
          </div>
          <div className="font-medium text-grey-600">
            <div>
              {moment(interaction.startDateTime).format("MMM Do [at] h:mm a")}
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="flex items-center gap-2">
          <div className="icon">
            <IoTimeOutline />
          </div>
          <div className="font-medium text-grey-600">
            <div>{interactionDuration}</div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="flex items-center gap-2">
          <div className="icon">
            <IoVideocamOutline />
          </div>
          <div className="font-medium text-grey-600">
            <div>{interaction.metaData.eventType ?? "Interaction"}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
