import { IInteractionsApiClient } from "../clients/interactions/IInteractionsApiClient";
import { Results } from "../models/Results";
import { Search } from "../models/Search";
import { SortBy } from "../models/SortBy";
import { Interaction } from "../models/Interaction";
import { CommVisionToken } from "../security/commVisionToken";
import { Participant } from "../clients/interactions/models/Participant";
import interactionResponseMapper from "../clients/interactions/models/responses/InteractionResponse";
import { IInteractionService } from "./IInteractionService";
import { ParticipantsResponse } from "../clients/interactions/models/responses/ParticipantsResponse";
import { DateRange } from "../models/DateRange";

export class InteractionService implements IInteractionService {
  constructor(private readonly interactionApiClient: IInteractionsApiClient) {}

  public async getInteractions(
    authDetails: CommVisionToken | null,
    page: number,
    pageSize: number,
    sortBy?: SortBy[],
    dateRange?: DateRange
  ): Promise<Results<Interaction>> {
    const response = await this.interactionApiClient.getInteractions(
      authDetails,
      page,
      pageSize,
      sortBy,
      dateRange
    );

    if (!response) {
      return { items: [], totalCount: 0, hasMore: false, resultsCount: 0 };
    }

    const data = response.results.map((x) =>
      interactionResponseMapper.toModel(x)
    );

    return {
      items: data,
      totalCount: response.totalCount,
      resultsCount: response.resultsCount,
      hasMore: response.hasMore,
    };
  }

  public async getInteractionsForParticipant(
    authDetails: CommVisionToken | null,
    participantId: string,
    filterByParticipantIsOrganizer: boolean,
    page: number,
    pageSize: number,
    sortBy?: SortBy[],
    dateRange?: DateRange,
    filterByAdditionalParticipantId?: string
  ): Promise<Results<Interaction>> {
    const response =
      await this.interactionApiClient.getInteractionsForParticipant(
        authDetails,
        participantId,
        filterByParticipantIsOrganizer,
        page,
        pageSize,
        sortBy,
        dateRange,
        filterByAdditionalParticipantId
      );

    if (!response) {
      return { items: [], totalCount: 0, hasMore: false, resultsCount: 0 };
    }

    const data = response.results.map((x) =>
      interactionResponseMapper.toModel(x)
    );

    return {
      items: data,
      totalCount: response.totalCount,
      resultsCount: response.resultsCount,
      hasMore: response.hasMore,
    };
  }

  public async getInteraction(
    authDetails: CommVisionToken | null,
    id: string
  ): Promise<Interaction | undefined> {
    const response = await this.interactionApiClient.getInteraction(
      authDetails,
      id
    );

    if (!response) {
      return undefined;
    }

    return interactionResponseMapper.toModel(response);
  }

  public async searchInteractions(
    authDetails: CommVisionToken | null,
    search: Search,
    page: number,
    pageSize: number,
    sortBy?: SortBy[],
    dateRange?: DateRange
  ): Promise<Results<Interaction>> {
    const response = await this.interactionApiClient.searchInteractions(
      authDetails,
      search,
      page,
      pageSize,
      sortBy,
      dateRange
    );

    if (!response) {
      return { items: [], totalCount: 0, hasMore: false, resultsCount: 0 };
    }

    const data = response.results.map((x) =>
      interactionResponseMapper.toModel(x)
    );

    return {
      items: data,
      totalCount: response.totalCount,
      resultsCount: response.resultsCount,
      hasMore: response.hasMore,
    };
  }

  public async searchInteractionsForParticipant(
    authDetails: CommVisionToken | null,
    participantId: string,
    filterByParticipantIsOrganizer: boolean,
    search: Search,
    page: number,
    pageSize: number,
    sortBy?: SortBy[],
    dateRange?: DateRange
  ): Promise<Results<Interaction>> {
    const response =
      await this.interactionApiClient.searchInteractionsForParticipant(
        authDetails,
        participantId,
        filterByParticipantIsOrganizer,
        search,
        page,
        pageSize,
        sortBy,
        dateRange
      );

    if (!response) {
      return { items: [], totalCount: 0, hasMore: false, resultsCount: 0 };
    }

    const data = response.results.map((x) =>
      interactionResponseMapper.toModel(x)
    );

    return {
      items: data,
      totalCount: response.totalCount,
      resultsCount: response.resultsCount,
      hasMore: response.hasMore,
    };
  }

  public async getParticipant(
    authDetails: CommVisionToken | null,
    id: string
  ): Promise<Participant> {
    return await this.interactionApiClient.getParticipant(authDetails, id);
  }

  public async searchParticipants(
    authDetails: CommVisionToken | null,
    search: Search,
    ignoreInternalParticipants: boolean,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<ParticipantsResponse> {
    return await this.interactionApiClient.searchParticipants(
      authDetails,
      search,
      ignoreInternalParticipants,
      page,
      pageSize,
      sortBy
    );
  }

  public async getParticipants(
    authDetails: CommVisionToken | null,
    ignoreInternalParticipants: boolean,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<ParticipantsResponse> {
    return await this.interactionApiClient.getParticipants(
      authDetails,
      ignoreInternalParticipants,
      page,
      pageSize,
      sortBy
    );
  }
}
