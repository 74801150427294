import SearchLg from "@untitled-ui/icons-react/build/esm/SearchLg";
import { DebouncedInput } from "../DebouncedInput";
import Avatar from "react-avatar";
import { Participant } from "../../clients/interactions/models/Participant";
import { MdOutlineClear } from "react-icons/md";
import { Tooltip } from "react-tooltip";

interface ParticipantFilterProps {
  onSetClick: (participantId: string, close: () => void) => void;
  close: () => void;
  participants: Participant[];
  onSearchChange: (searchPhrase: string) => void;
  participantsSearchText?: string;
  filteredParticipantId?: string;
  onClearFilterClick: (close: () => void) => void;
}

const ParticipantFilter = ({
  onSetClick,
  close,
  participants,
  onSearchChange,
  participantsSearchText,
  filteredParticipantId,
  onClearFilterClick,
}: ParticipantFilterProps) => {
  const selectedParticipant = participants.find(
    (participant) => participant.id === filteredParticipantId
  );

  return (
    <div className="min-w-[300px] p-2 max-h-[495px]">
      <div className="flex flex-row items-center border border-gray-300 rounded-md">
        <div className="p-2">
          <SearchLg />
        </div>
        <DebouncedInput
          value={participantsSearchText ?? ""}
          onChange={onSearchChange}
          placeholder="Search by name, email or company"
          delay={500} // Debounce delay in milliseconds
          className="w-full"
        />
      </div>
      <div className="flex flex-col mt-5 ml-1">
        {selectedParticipant && (
          <div className="flex flex-col mb-5">
            <span className="text-md text-gray-800">Filtered by</span>
            <div
              key={selectedParticipant.id}
              className="flex items-center mt-2"
            >
              <button
                className="p-0 m-0"
                onClick={() => onClearFilterClick(close)}
              >
                <Tooltip id={selectedParticipant.id} />
                <MdOutlineClear
                  className="size-5 text-red-500 focus:outline-none"
                  data-tooltip-id={selectedParticipant.id}
                  data-tooltip-content="Clear filter"
                  data-tooltip-place="left"
                />
              </button>
              <div className="flex-col ml-3 text-md text-gray-800 items-center justify-items-start">
                {selectedParticipant.name ?? selectedParticipant.email}
                {selectedParticipant.company &&
                  ` | ${selectedParticipant.company.name}`}
                {selectedParticipant.name && (
                  <div className="text-sm text-gray-500">
                    {selectedParticipant.email}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <span className="text-md text-gray-800">
          {!participantsSearchText && "Recent participants"}
          {participantsSearchText && "Participants"}
        </span>
        <div className="mt-2 max-h-[390px] overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
          <ul className="list-none">
            {participants.map((participant) => (
              <li className="mb-1 p-1">
                <button
                  className="p-0 m-0"
                  onClick={() => onSetClick(participant.id, close)}
                >
                  <div key={participant.id} className="flex items-center">
                    <Avatar
                      name={participant.name}
                      size="35"
                      round={true}
                      textMarginRatio={0.05}
                    />
                    <div className="flex-col ml-3 text-md text-gray-800 items-center justify-items-start">
                      {participant.name ?? participant.email}
                      {participant.company && ` | ${participant.company.name}`}
                      {participant.name && (
                        <div className="text-sm text-gray-500">
                          {participant.email}
                        </div>
                      )}
                    </div>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ParticipantFilter;
