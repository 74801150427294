import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { routes } from "../routes";
import AuthButton from "../components/AuthButton";
import { Tooltip } from "react-tooltip";

const Sidebar = () => {
  const location = useLocation();

  const currentRoute = useMemo(() => {
    return routes.find((route) => matchPath(route.path, location.pathname));
  }, [location.pathname]);

  const settingsRoute = currentRoute?.sidebarNavItems?.find(
    (x) => x.name === "Settings"
  );

  return (
    <div className="flex flex-col items-center w-24 h-full fixed overflow-hidden text-commVision-300 text-lg bg-commVision-800">
      <a
        className="flex items-center justify-center mt-5 w-[50px]"
        href="https://commvision.ai"
      >
        <img src="/logo.svg" alt="logo" className="w-[80%]" />
      </a>
      <div className="flex flex-col items-center mt-3">
        {currentRoute?.sidebarNavItems
          ?.filter((x) => x.name !== "Settings")
          .map((item) => (
            <div key={item.name}>
              <Tooltip id={item.name} />
              <a
                className="flex items-center justify-center w-16 h-16 text-md mt-2 rounded hover:bg-commVision-500 hover:text-white"
                href={item.path}
                data-tooltip-id={item.name}
                data-tooltip-content={item.toolTip}
                data-tooltip-place="right"
                data-tooltip-float={true}
                data-tooltip-delay-show={350}
              >
                <item.icon />
              </a>
            </div>
          ))}
      </div>
      <div className="align-bottom flex flex-col items-center mt-auto">
        {settingsRoute && (
          <div>
            <Tooltip id={settingsRoute.name} />
            <a
              key={settingsRoute.name}
              className="flex items-center justify-center w-16 h-16 text-md mt-2 rounded hover:bg-commVision-500 hover:text-white"
              href={settingsRoute.path}
              data-tooltip-id={settingsRoute.name}
              data-tooltip-content={settingsRoute.toolTip}
              data-tooltip-place="right"
              data-tooltip-float={true}
              data-tooltip-delay-show={350}
            >
              <settingsRoute.icon />
            </a>
          </div>
        )}
        <div className="cursor-pointer flex items-center justify-center w-24 h-16 mt-auto mb-5 bg-commVision-800 hover:bg-commVision-300 hover:text-commVision-800">
          <AuthButton />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
