import { Search } from "../../../models/Search";

export namespace SearchRequest {
  export function ConvertFrom(search: Search): string {
    let convertedSearch: string;

    if (!search.phrase) {
      convertedSearch = `{"phrase": null, "searchOn": ${JSON.stringify(
        search.searchOn
      )}}`;
    }

    convertedSearch = `{"phrase": "${
      search.phrase
    }", "searchOn": ${JSON.stringify(search.searchOn)}}`;

    return convertedSearch;
  }
}
