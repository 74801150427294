import { Status } from "../models/ProcessesStatus";
import { Tooltip } from "react-tooltip";
import StatusContainer from "./StatusContainer";
import Avatar from "react-avatar";
import { Interaction } from "../models/Interaction";
import Pagination, { PaginationProps } from "./Pagination";
import SearchLg from "@untitled-ui/icons-react/build/esm/SearchLg";
import moment from "moment";
import SortableTableHeading from "./SortableTableHeading";
import { SortDirection } from "../models/SortBy";
import { DebouncedInput } from "./DebouncedInput";
import FilterMenu from "./filters/FilterMenu";
import { Range } from "react-date-range";
import DateRangeFilter from "./filters/DateRangeFilter";
import ParticipantFilter from "./filters/ParticipantFilter";
import { Participant } from "../clients/interactions/models/Participant";

export interface ParticipantFilterProps {
  participants: Participant[];
  onParticipantSearchChange: (searchPhrase: string) => void;
  onParticipantClearFilterClick: (close: () => void) => void;
  participantsSearchText?: string;
  filteredParticipantId?: string;
  onParticipantFilterSetClick: (
    participantId: string,
    close: () => void
  ) => void;
}

export interface DateRangeFilterProps {
  onSetDateRangeClick: (dateRange: Range, close: () => void) => void;
  initialFilterDateRange: Range;
}

export interface SortColumnProps {
  sortingDirection: SortDirection;
  activeColumns: string[];
  sortingColumns: string[];
  sortByColumnOnClick: (column: string, sortDirection: SortDirection) => void;
}

interface InteractionsListProps {
  interactions: Interaction[];
  pagination: PaginationProps;
  sortColumns: SortColumnProps;
  onSearchChange: (searchPhrase: string) => void;
  enableFilterByOrganizer?: boolean;
  filterByParticipantIsOrganizer?: boolean;
  onIsOrganizerClick?: (filterByOrganizer: boolean) => void;
  dateRangeFilter: DateRangeFilterProps;
  participantFilter: ParticipantFilterProps;
}

const InteractionsList = ({
  interactions,
  pagination,
  sortColumns,
  onSearchChange,
  participantFilter,
  enableFilterByOrganizer = false,
  filterByParticipantIsOrganizer = false,
  onIsOrganizerClick = () => {},
  dateRangeFilter,
}: InteractionsListProps) => {
  return (
    <>
      <div className="flex mb-6">
        <div className="flex items-center justify-start w-1/2">
          {/* <button className="flex items-center justify-center p-6 h-10 text-md border-slate-300 text-gray-700 border font-bold rounded-lg">
            All Time
            <span>
              <IoClose size={28} />
            </span>
          </button> */}
        </div>
        <div className="flex items-center justify-end w-1/2">
          {enableFilterByOrganizer && (
            <label className="inline-flex items-center mb-0 mr-5 cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={filterByParticipantIsOrganizer}
                onChange={(e) => onIsOrganizerClick(e.target.checked)}
              />
              <div className="relative w-10 h-5 rounded-full peer bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all border-gray-600 peer-checked:gray-700" />
              <span className="ms-3 text-sm font-medium">Is Organizer</span>
            </label>
          )}

          <div className="flex flex-row items-center border border-gray-300 rounded-md">
            <div className="p-2">
              <SearchLg />
            </div>
            <DebouncedInput
              value={""}
              onChange={onSearchChange}
              placeholder="Type to search..."
              delay={500} // Debounce delay in milliseconds
              className="w-full"
            />
          </div>
        </div>
      </div>
      <div className="">
        {/* table has light grey border around each row with first column big padding */}
        <table className="table-auto w-full text-left min-w-full divide-y divide-gray-200  border border-gray-200 rounded-lg p-3">
          <thead>
            <tr>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                <div className="relative w-full">
                  <div className="flex items-center justify-start relative">
                    <SortableTableHeading
                      headingText="Date"
                      headingToSort="StartDateTime"
                      sortColumnDirection={sortColumns.sortingDirection}
                      activeColumns={sortColumns.activeColumns}
                      sortingColumns={sortColumns.sortingColumns}
                      sortByColumnOnClick={sortColumns.sortByColumnOnClick}
                    />
                    <FilterMenu
                      position="left"
                      filterComponent={(close) => (
                        <DateRangeFilter
                          close={close}
                          onSetDateRangeClick={
                            dateRangeFilter.onSetDateRangeClick
                          }
                          initialDateRange={
                            dateRangeFilter.initialFilterDateRange
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              </th>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                <SortableTableHeading
                  headingText="Subject"
                  headingToSort="Subject"
                  sortColumnDirection={sortColumns.sortingDirection}
                  activeColumns={sortColumns.activeColumns}
                  sortingColumns={sortColumns.sortingColumns}
                  sortByColumnOnClick={sortColumns.sortByColumnOnClick}
                />
              </th>

              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                <SortableTableHeading
                  headingText="Total Duration"
                  headingToSort="TotalDurationInSeconds"
                  sortColumnDirection={sortColumns.sortingDirection}
                  activeColumns={sortColumns.activeColumns}
                  sortingColumns={sortColumns.sortingColumns}
                  sortByColumnOnClick={sortColumns.sortByColumnOnClick}
                />
              </th>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                Companies
              </th>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                <div className="relative w-full">
                  <div className="flex items-center justify-start relative">
                    Participants
                    <FilterMenu
                      position="right"
                      filterComponent={(close) => (
                        <ParticipantFilter
                          close={close}
                          onSetClick={
                            participantFilter.onParticipantFilterSetClick
                          }
                          participants={participantFilter.participants}
                          onSearchChange={
                            participantFilter.onParticipantSearchChange
                          }
                          participantsSearchText={
                            participantFilter.participantsSearchText
                          }
                          filteredParticipantId={
                            participantFilter.filteredParticipantId
                          }
                          onClearFilterClick={
                            participantFilter.onParticipantClearFilterClick
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              </th>

              <th className="w-[120px] px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                Process Status
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 ">
            {interactions.map((interaction) => {
              return (
                <tr key={interaction.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    {moment(interaction.startDateTime).format(
                      "MMM Do [at] h:mm a"
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-bold text-gray-800 ">
                    <a href={`/interactions/${interaction.id}`}>
                      {interaction.subject}
                    </a>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    {moment
                      .duration(
                        Number(interaction.totalDurationInSeconds),
                        "seconds"
                      )
                      .humanize()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    <div className="flex -space-x-2 rtl:space-x-reverse">
                      {interaction.companies
                        .slice(0, 5)
                        .map((company, index) => (
                          <div
                            key={index + company.id}
                            className="rounded-full "
                          >
                            <Tooltip id={index + company.id} />

                            <Avatar
                              name={company.name}
                              size="30"
                              round={true}
                              textMarginRatio={0.05}
                              data-tooltip-id={index + company.id}
                              data-tooltip-content={company.name}
                              data-tooltip-place="left"
                            />
                          </div>
                        ))}

                      {interaction.companies?.length > 5 && (
                        <div className="cursor-pointer flex items-center justify-center w-7 text-xs font-medium text-white bg-gray-700 rounded-full hover:bg-gray-600  !ml-1">
                          <span>+{interaction.companies.length - 5}</span>
                        </div>
                      )}
                    </div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    <div className="flex -space-x-2 rtl:space-x-reverse">
                      {interaction.participants
                        .slice(0, 5)
                        .map((participant, index) => (
                          <div
                            key={index + participant.id}
                            className="rounded-full "
                          >
                            <Tooltip id={index + participant.id} />
                            <a
                              href={`/${
                                participant.isInternal
                                  ? "team_members"
                                  : "participants"
                              }/${participant.id}`}
                            >
                              <Avatar
                                name={participant.name}
                                size="30"
                                round={true}
                                textMarginRatio={0.05}
                                data-tooltip-id={index + participant.id}
                                data-tooltip-content={participant.name}
                                data-tooltip-place="left"
                              />
                            </a>
                          </div>
                        ))}

                      {interaction.participants.length > 5 && (
                        <div className="cursor-pointer flex items-center justify-center w-7 text-xs font-medium text-white bg-gray-700 rounded-full hover:bg-gray-600  !ml-1">
                          <span>+{interaction.participants.length - 5}</span>
                        </div>
                      )}
                    </div>
                  </td>

                  <td className="flex items-center justify-start px-6 py-4 whitespace-nowrap text-md font-medium">
                    <StatusContainer
                      status={
                        interaction.metaData.processStatus?.status ??
                        Status.Error
                      }
                      correlationId={interaction.metaData.correlationId}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {pagination.totalItems > pagination.itemsPerPage && (
          <Pagination
            currentPage={pagination.currentPage}
            itemsPerPage={pagination.itemsPerPage}
            totalItems={pagination.totalItems}
            onPageChange={pagination.onPageChange}
          />
        )}
      </div>
    </>
  );
};

export default InteractionsList;
