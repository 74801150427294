import { useCallback, useEffect, useState } from "react";
import { useAuthDetails } from "../../hooks/useAuthDetails";
import { logger } from "../../infrastructure/logger";
import { PaginationProps } from "../../components/Pagination";
import BreadCrumb from "../../components/BreadCrumb";
import HomeLine from "@untitled-ui/icons-react/build/esm/HomeLine";
import { SortDirection } from "../../models/SortBy";
import { Search } from "../../models/Search";
import { LoadingCard } from "../../components/LoadingCard";
import { TeamMemberWithLatestInteraction } from "../../models/TeamMemberWithLatestInteraction";
import { InteractionsApiClient } from "../../clients/interactions/InteractionsApiClient";
import { ITeamMembersService } from "../../services/ITeamMembersService";
import { TeamMembersService } from "../../services/TeamMembersService";
import TeamMembersList from "../../components/TeamMembersList";

const teamMembersService: ITeamMembersService = new TeamMembersService(
  new InteractionsApiClient()
);

const TeamMembersPage = () => {
  const defaultItemsPerPage = 25;
  const defaultSortingColumn = "Name";

  const authDetails = useAuthDetails();
  const [loading, setLoading] = useState(false);
  const [resultsAreFiltered, setResultsAreFiltered] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [teamMembers, setTeamMembers] = useState<
    TeamMemberWithLatestInteraction[]
  >([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [activeColumn, setActiveColumn] = useState([defaultSortingColumn]);
  const [sortingColumn, setSortingColumn] = useState([defaultSortingColumn]);
  const [sortingDirection, setSortingDirection] = useState<SortDirection>(
    SortDirection.Ascending
  );
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginationSettings: PaginationProps = {
    currentPage,
    itemsPerPage,
    totalItems,
    onPageChange: handlePageChange,
  };

  const loadTeamMembers = useCallback(
    async (sortByColumn?: string, sortByDirection?: SortDirection) => {
      if (!authDetails) return;

      const sortBy =
        sortByColumn && sortByDirection
          ? [{ propertyName: sortByColumn, direction: sortByDirection }]
          : undefined;

      const data = await teamMembersService.getTeamMembersWithInteractions(
        authDetails,
        currentPage,
        itemsPerPage,
        sortBy
      );

      if (!data?.items) {
        return;
      }

      setTeamMembers(data.items || []);
      setTotalItems(data.totalCount || 0);
      setResultsAreFiltered(false);
      setSearchText(undefined);
    },
    [authDetails, currentPage, itemsPerPage]
  );

  const loadFilteredTeamMembers = useCallback(
    async (
      searchFor: string,
      sortByColumn?: string,
      sortByDirection?: SortDirection
    ) => {
      if (!authDetails) return false;

      const sortBy =
        sortByColumn && sortByDirection
          ? [{ propertyName: sortByColumn, direction: sortByDirection }]
          : undefined;

      const search: Search = {
        phrase: searchFor,
        searchOn: ["Name", "Email", "Subject"],
      };

      const data = await teamMembersService.searchTeamMembersWithInteractions(
        authDetails,
        search,
        currentPage,
        itemsPerPage,
        sortBy
      );

      if (data.totalCount > totalItems) {
        setItemsPerPage(itemsPerPage);
      }

      setTeamMembers(data.items || []);
      setTotalItems(data.totalCount || 0);
      setResultsAreFiltered(true);
      setSearchText(searchFor);

      if (!sortBy) {
        setActiveColumn([]);
        setSortingColumn([]);
      }
    },
    [authDetails, currentPage, totalItems, itemsPerPage]
  );

  const onSearchChange = useCallback(
    async (searchFor: string | undefined) => {
      if (!authDetails) return;

      if (searchFor) {
        await loadFilteredTeamMembers(searchFor);
      } else {
        await loadTeamMembers();
        setSearchText(undefined);
        setActiveColumn([]);
        setSortingColumn([]);
      }
    },
    [authDetails, loadFilteredTeamMembers, loadTeamMembers]
  );

  useEffect(() => {
    setLoading(true);
    loadTeamMembers()
      .catch((e) => {
        if (e instanceof Error) {
          setError(e.message);
          logger.error("loadTeamMebers", { error: e });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loadTeamMembers]);

  const breadCrumbs = [
    { name: "Home", link: "/", icon: HomeLine },
    { name: "Team Members", link: "/team_members" },
  ];

  const sortByColumnOnClick = (column: string) => {
    if (sortingColumn?.includes(column)) {
      if (resultsAreFiltered) {
        loadFilteredTeamMembers(searchText!, column, SortDirection.Descending);
      } else {
        loadTeamMembers(column, SortDirection.Descending);
      }

      setSortingColumn([]);
      setSortingDirection(SortDirection.Descending);
    } else {
      if (resultsAreFiltered) {
        loadFilteredTeamMembers(searchText!, column, SortDirection.Ascending);
      } else {
        loadTeamMembers(column, SortDirection.Ascending);
      }

      setSortingColumn([`${column}`]);
      setSortingDirection(SortDirection.Ascending);
    }

    setActiveColumn([`${column}`]);
  };

  return (
    <div>
      <BreadCrumb items={breadCrumbs} />
      <h1 className="text-4xl capitalize font-bold mb-8">Staff Overview</h1>
      <div className="mt-5">
        {error && (
          <div className="row">
            <div className="card large error">
              <section>
                <p>
                  <span className="icon-alert inverse "></span>
                  {error}
                </p>
              </section>
            </div>
          </div>
        )}

        <TeamMembersList
          teamMembers={teamMembers}
          pagination={paginationSettings}
          sortDirection={sortingDirection}
          activeColumns={activeColumn}
          sortingColumns={sortingColumn}
          sortByColumnOnClick={sortByColumnOnClick}
          onSearchChange={onSearchChange}
        />

        {loading && (
          <div className="center-page">
            <span className="spinner primary"></span>
            <LoadingCard />
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamMembersPage;
