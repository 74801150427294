import moment from "moment";
import { useState } from "react";
import { DateRangePicker, Range } from "react-date-range";

interface DateRangeFilterProps {
  onSetDateRangeClick: (dateRange: Range, close: () => void) => void;
  close: () => void;
  initialDateRange?: Range;
}

const DateRangeFilter = ({
  onSetDateRangeClick,
  close,
  initialDateRange,
}: DateRangeFilterProps) => {
  const defaultDateFilterRange = {
    startDate: moment().subtract(3, "months").toDate(),
    endDate: new Date(),
    key: "selection",
  };

  const [dateFilterRange, setDateFilterRange] = useState<Range>(
    initialDateRange ?? defaultDateFilterRange
  );

  return (
    <div>
      <DateRangePicker
        onChange={(item) => setDateFilterRange(item.selection)}
        moveRangeOnFirstSelection={true}
        months={2}
        ranges={dateFilterRange ? [dateFilterRange] : undefined}
        direction="horizontal"
        showDateDisplay={true}
        showPreview={true}
        retainEndDateOnFirstSelection={true}
      />
      <div className="flex justify-between items-center mb-2">
        <div>
          <button
            className="bg-[#4e9bff] text-white w-20 h-8 ml-3 rounded-lg hover:bg-commVision-500 hover:text-white"
            onClick={() => setDateFilterRange(defaultDateFilterRange)}
          >
            Reset
          </button>
        </div>
        <div className="justify-end mr-4">
          <button
            className="bg-commVision-800 text-white w-20 h-8 rounded-lg hover:bg-commVision-500 hover:text-white"
            onClick={() => onSetDateRangeClick(dateFilterRange, close)}
          >
            Set
          </button>
          <button
            className="bg-[#4e9bff] text-white w-20 h-8 ml-3 rounded-lg hover:bg-commVision-500 hover:text-white"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateRangeFilter;
