import { ThinParticipant } from "../../../../models/ThinParticipant";
import { Attendance } from "../InteractionParticipant";

import thinCompanyResponseMapper, {
  ThinCompanyResponse,
} from "./ThinCompanyResponse";

export type ThinParticipantResponse = {
  id: string;
  customerId: string;
  createdOn: Date;
  name?: string;
  email?: string;
  company?: ThinCompanyResponse;
  isInternal?: boolean;
  isExternal?: boolean;
  isOrganizer?: boolean;
  attendance?: Attendance[];
};

const thinParticipantMapper = {
  toModel(response: ThinParticipantResponse): ThinParticipant {
    const result = {
      id: response.id,
      customerId: response.customerId,
      createdOn: response.createdOn,
      name: response.name,
      email: response.email,
      company: response.company
        ? thinCompanyResponseMapper.toModel(response.company)
        : undefined,
      isInternal: response.isInternal ?? false,
      isExternal: response.isExternal ?? false,
      isOrganizer: response.isOrganizer ?? false,
      attendances: response.attendance ?? [],
    };

    return result;
  },
};

export default thinParticipantMapper;
