import { IInteractionsApiClient } from "../clients/interactions/IInteractionsApiClient";
import teamMemberWithInteractionResponseMapper from "../clients/interactions/models/responses/TeamMemberWithLatestInteractionResponse";
import { Results } from "../models/Results";
import { Search } from "../models/Search";
import { SortBy } from "../models/SortBy";
import { TeamMemberWithLatestInteraction } from "../models/TeamMemberWithLatestInteraction";
import { CommVisionToken } from "../security/commVisionToken";
import { ITeamMembersService } from "./ITeamMembersService";

export class TeamMembersService implements ITeamMembersService {
  constructor(private readonly interactionApiClient: IInteractionsApiClient) {}

  public async getTeamMembersWithInteractions(
    authDetails: CommVisionToken | null,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<Results<TeamMemberWithLatestInteraction>> {
    const response =
      await this.interactionApiClient.getTeamMembersWithInteractions(
        authDetails,
        page,
        pageSize,
        sortBy
      );

    if (!response) {
      return { items: [], totalCount: 0, hasMore: false, resultsCount: 0 };
    }

    const data = response.results.map((x) =>
      teamMemberWithInteractionResponseMapper.toModel(x)
    );

    return {
      items: data,
      totalCount: response.totalCount,
      resultsCount: response.resultsCount,
      hasMore: response.hasMore,
    };
  }

  public async searchTeamMembersWithInteractions(
    authDetails: CommVisionToken | null,
    search: Search,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<Results<TeamMemberWithLatestInteraction>> {
    const response =
      await this.interactionApiClient.searchTeamMembersWithInteractions(
        authDetails,
        search,
        page,
        pageSize,
        sortBy
      );

    if (!response) {
      return { items: [], totalCount: 0, hasMore: false, resultsCount: 0 };
    }

    const data = response.results.map((x) =>
      teamMemberWithInteractionResponseMapper.toModel(x)
    );

    return {
      items: data,
      totalCount: response.totalCount,
      resultsCount: response.resultsCount,
      hasMore: response.hasMore,
    };
  }
}
